.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hidden {
  display: none;
}

.descriptions-warning {
  font-weight: bold !important;
  color: red !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.menu-logo {
  height: 100px;
  width: auto;
  margin: auto;
}

.loading-logo {
  height: 50vh !important;
  width: auto !important;
  border-radius: 250px;
  animation: pulsate 1.5s infinite ease-out;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    filter: brightness(100%);
  }

  50% {
    box-shadow: 0 0 20px 40px rgba(0, 0, 0, 0);
    filter: brightness(80%);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    filter: brightness(100%);
  }
}

.login-body,
.login-body .ant-space-item {
  width: 100%;
}

.login-body button,
.login-body button div:first {
  width: 100% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  z-index: 9999;
  /* Ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-group-stretched {
  width: 100%;
  text-align: center;
}

.full-width {
  width: 100%;
}

.ant-image-preview-mask {
  z-index: 2000 !important;
}

.ant-image-preview-wrap {
  z-index: 2001 !important;
}

.ant-image-preview-operations-wrapper {
  z-index: 2002 !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}