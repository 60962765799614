.drawer-menu-table-mobile th,
.drawer-menu-table-mobile .ant-table-cell {
    padding: 2px !important;
}

.drawer-menu-table-mobile .ant-table-cell {
    display: table-cell !important;
    align-content: center !important;
    height: 35px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}