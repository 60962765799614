.leaflet-popup-content-wrapper {
    padding: 0;
    text-align: center;
    border-radius: 12px;
    background-color: transparent;
}

.leaflet-popup-content {
    margin: 0;
    width: auto !important;
}

.primary-popup {
    background-color: #c9306c;
    border-radius: 12px;
    padding-top: 5px;
}

.primary-popup .leaflet-popup-tip {
    background-color: #c9306c;
}

.primary-popup i {
    color: white;
    font-weight: bold;
    font-size: 35px;
    padding: 5px;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.tile-layer {
    transition: opacity 300ms linear
}

.animated-polyline {
    stroke-dasharray: 15;
    stroke-dashoffset: 30;
    animation: dash 0.5s linear infinite;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.hover-pointer:hover {
    cursor: pointer;
}

.route-description .ant-descriptions-view {
    border-radius: 0 !important;
}

.route-description-mobile .ant-descriptions-item-label,
.route-description-mobile .ant-descriptions-item-content {
    padding: 2px 16px !important;
}